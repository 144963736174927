<template>
  <div class="w-full">
    <div class="w-full fixed top-0 bottom-0 left-0 p-1 pt-2" style="overflow-y:auto;">
      <div class="w-full">
        <table class="w-full h-full" style="overflow-y:scroll;">
          <tbody>
            <tr>
              <th colspan="4" style="background:transparent">REPORT</th>
              <th colspan="10" style="background:transparent">RESULT</th>
              <th colspan="3" style="background:transparent">META</th>
            </tr>
            <tr>
              <th colspan="1" width="100">id</th>
              <th colspan="1" width="100">group</th>
              <th colspan="1" width="100">asset</th>
              <th colspan="1" width="240">stamp</th>
              <th colspan="2" width="100">performance</th>
              <th colspan="2" width="100">accessibility</th>
              <th colspan="2" width="100">best pratices</th>
              <th colspan="2" width="100">seo</th>
              <th colspan="2">total</th>
              <th colspan="1" width="150">node</th>
              <th colspan="1" width="150">host</th>
              <th colspan="1" width="30"></th>
            </tr>
            <tr>
              <th colspan="4" style="background:transparent">&nbsp; -</th>
              <th colspan="10" style="background:transparent">&nbsp;</th>
              <th colspan="3" style="background:transparent">&nbsp;</th>
            </tr>
          </tbody>
          <tbody>
            <tr v-for="(object) in b" v-bind:key="object.id">
              <td>{{object.id}}</td>
              <td>1</td>
              <td>{{object.asset_id}}</td>
              <td>{{object.created}}</td> 
              <td width="100">{{object.score_a}}</td>
              <td width="100">&nbsp;</td>
              <td width="100">{{object.score_b}}</td>
              <td width="100">&nbsp;</td>
              <td width="100">{{object.score_c}}</td>
              <td width="100">&nbsp;</td>
              <td width="100">{{object.score_d}}</td>
              <td width="100">&nbsp;</td>
              <td width="100">{{object.score}}</td>
              <td width="100">&nbsp;</td>
              <td>{{object.type}}</td>
              <td>{{object.mode}}</td>
              <td><input type="checkbox" value="mark" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="fixed right-0 bottom-0 h-16 w-96 bg-grey">
      <div class="col-md-6 text-right">
        <button class="border border-black opacity-60 mr-5" @click="setAll">Reset Status</button>
        <button class="border border-black opacity-60 mr-5" @click="setAssets">Reset Locks</button>
        <button class="border border-black opacity-60 mr-5" @click="setUpdated">Reset Updated</button>
        <button class="border border-black opacity-60 mr-5" @click="setReports">Empty Reports</button>
      </div>
    </div>
  </div>
</template>
<script>
import DatabaseService from "../Database.service";
export default
  {
    data: () => (
      {
        database: undefined,
        a: [],_a_: undefined,
        b: [],_b_: undefined,
        c: [],_c_: undefined,
        axis: [{y: 100},{y: 90},{y: 80},{y: 70},{y: 60},{y: 50},{y: 40},{y: 30},{y: 20},{y: 10},{y: 0}],
        series: [{x: -90},{x: -80},{x: -70},{x: -60},{x: -50},{x: -40},{x: -30},{x: -20},{x: -10},{x: 0}],
      }),
    created() 
    {
      const connect=new DatabaseService();
      this.database=connect.getInstance();
    },
    async mounted() 
    {
      await this.fetchA();
      await this.fetchB();
      this.subscribeA();
      this.subscribeB();
    },
    methods:
    {
      getNumber(string)
      {
        // TODO: round to 1 decomal place converting string to number

        console.log(string);
      },
      async setAll() 
      {
        await this.database.from("assets").update({status: null,lock: null,updated: null}).neq("id","0");
        await this.database.from("reports").delete().neq("id","0");
        await this.fetchA();
        await this.fetchB();
      },
      async setReports() 
      {
        await this.database.from("reports").delete().neq("id","0");
        await this.fetchB();
      },
      async setUpdated() 
      {
        await this.database.from("assets").update({updated: null}).neq("id","0");
        await this.fetchA();
      },
      async setAssets() 
      {
        await this.database.from("assets").update({status: 1,lock: null}).match({status: 2});
        await this.fetchA();
      },
      async fetchA()
      {
        let {error,data}=await this.database.from("assets").select('id,host,rank,lock,status,updated').order("id",{ascending: true}).limit(100);
        if(error) {console.log(error);}
        else {this.setA(data);}
      },
      async fetchB()
      {
        let {error,data}=await this.database
        .from("reports").select('*')
        .order("id",{ascending:false})
        .limit(100);
        if(error) {console.log(error);}
        else {this.setB(data);}
      },
      setA(a) {this.a=a;},
      setB(b) {this.b=b;},
      subscribeA() {this._a_=this.database.from('assets').on('*',() => this.fetchA()).subscribe();},
      subscribeB() {this._b_=this.database.from('reports').on('*',() => this.fetchB()).subscribe();}
    },
  };
</script>
<style type="text/css" scoped>
table
{
  border-color: #aaa
}

table td,
table th
{
  text-align: left;
  padding: 4px 8px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
}

table th
{
  background: #222;
  font-size: 12px;
  color: #aaa;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.a
{}
</style>