import { createClient } from '@supabase/supabase-js';

export default class DatabaseService
{
  constructor()
  {
    this.database = createClient('https://epfkpnqatmpwdvclgrrr.supabase.co','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVwZmtwbnFhdG1wd2R2Y2xncnJyIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcwMzk2MDY3NiwiZXhwIjoyMDE5NTM2Njc2fQ.wP9DJiYIgy8n2qjoa0YbtcCQZHI8GPAZtkeZfaXN5-A')
  }
  getInstance()
  {
    return this.database;
  }
}