<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default 
{
  name: "App"
}
</script>
<style type="text/css">

body {font-size: 12px; font-family: monospace;}

table,td,th,div,span,a,strong,b,i,u {box-sizing:border-box;}

/**/

::-webkit-scrollbar {width: 10px;}
::-webkit-scrollbar-track {background: #222;}
::-webkit-scrollbar-thumb {background: #333;}
::-webkit-scrollbar-thumb:hover {background: #555;}

/**/

button {color:#ccc;padding: 4px 10px;}
button:hover {color:#fff;background-color:#333;}

/**/
</style>