import Rute from 'vue-router';
import Main from '@/components/Main';

export default new Rute
(
  {
    mode: 'history',
    routes:
    [
        {path: '/',name:'Main',component:Main},
        {path: '*',redirect: '/'}
    ]
  }
);
